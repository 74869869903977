<template lang="pug">
include ../../../configs/template
div.row.w-100
  div.col-12
    +select-validation('data.comments.additional_info.reason', 'rejectionReasons', 'rejectionReasonStatement', '"name_ukr"', '["required"]')
  div.col-12
    +textarea-validation('data.comments.comment', 'comment', '["required", "alphaUAWithSpecialCharacters"]')
  div.col-12
    v-btn(v-if="statusDocument === STATUSES_DOCUMENT_CONSTANTS.SAILOR.EXPERIENCE.SERVICE_RECORD_BOOK.REJECTED" color="error" @click="setReject" :loading="isLoading") {{$t('setReject')}}
    v-btn(v-else color="success" @click="setReject" :loading="isLoading") {{$t('btn.save')}}
</template>
<script>
import { RejectFormSailorPassport } from '@/mixins/validationRules'
import { mapActions, mapState } from 'vuex'
import { STATUSES_DOCUMENT_CONSTANTS } from '@/configs/constants'

export default {
  props: {
    statusDocument: {
      type: Number,
      default: STATUSES_DOCUMENT_CONSTANTS.SAILOR.EXPERIENCE.SERVICE_RECORD_BOOK.REJECTED
    }
  },
  data () {
    return {
      STATUSES_DOCUMENT_CONSTANTS,
      data: {
        ...this.$route.params,
        comments: {
          comment: null,
          additional_info: {
            reason: null,
            cancel_reason: true
          }
        },
        body: {
          status_document: this.statusDocument
        }
      },
      isLoading: false
    }
  },
  validations () { return { data: RejectFormSailorPassport() } },
  computed: {
    ...mapState({
      rejectionReasons: state => state.directory.rejectionReasons.filter(item => item.model_name !== 'lineinservicerecord')
    })
  },
  methods: {
    ...mapActions(['updateRecordBookById']),
    async setReject () {
      if (this.$v.$invalid) return this.$v.$touch()
      this.isLoading = true
      if (this.statusDocument === STATUSES_DOCUMENT_CONSTANTS.SAILOR.EXPERIENCE.SERVICE_RECORD_BOOK.REJECTED) {
        this.$swal({
          title: this.$t('warning'),
          text: this.$t('rejectingConfirmation'),
          icon: 'info',
          buttons: [this.$t('cancel'), this.$t('confirm')],
          dangerMode: true
        }).then(async (confirmation) => {
          if (confirmation) {
            await this.updateRecordBookById(this.data)
            this.$v.$reset()
          }
          this.isLoading = false
        })
      } else {
        this.isLoading = true
        await this.updateRecordBookById(this.data)
        this.isLoading = false
      }
    }
  }
}
</script>
